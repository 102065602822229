import request from '@/utils/request'
const API = '/api/users'

export function User(params, action) {
  var id = null

  if (params.id) {
    id = params.id
  }
  if (action === 'create') return create(params)
  if (action === 'update') return update(id, params)
  if (action === 'remove') return remove(id, params)
  if (action === 'add_company') return addCompany(id, params)
  if (action === 'remove_company') return removeCompany(id, params)
  if (action === 'get_companies') return getCompanies(id, params)
  if (action === 'get_company') return getCompany(params)
  if (action === 'send_recovery_link') return sendRecoveryLink(params)
  if (action === 'verify_token') return verifyToken(params)
  if (action === 'set_new_password') return setNewPassword(params)
  if (action === 'analytics') return getAnalytics(params)
  else return all(params)
}

function all(params) {
  return request({
    url: API,
    method: 'get',
    params
  })
}

function sendRecoveryLink(params) {
  return request({
    url: 'api/send_recovery_link',
    method: 'post',
    params
  })
}

function verifyToken(params) {
  return request({
    url: 'api/verify_token',
    method: 'post',
    params
  })
}

function setNewPassword(params) {
  return request({
    url: 'api/set_new_password',
    method: 'post',
    data: params
  })
}

function getAnalytics() {
  return request({
    url: '/api/analytics',
    method: 'get'
  })
}

function create(params) {
  return request({
    url: API,
    method: 'post',
    data: params
  })
}

function update(id, params) {
  return request({
    url: API + '/' + id,
    method: 'put',
    data: params
  })
}

function remove(id, params) {
  return request({
    url: API + '/' + id,
    method: 'delete'
  })
}

function addCompany(id, params) {
  return request({
    url: API + '/' + id + '/add_company',
    method: 'post',
    params
  })
}

function getCompanies(id, params) {
  return request({
    url: API + '/' + id + '/companies',
    method: 'get',
    params
  })
}

function getCompany(params) {
  return request({
    url: API + '/company',
    method: 'get',
    params
  })
}

function removeCompany(id, params) {
  return request({
    url: API + '/' + id + '/remove_company',
    method: 'delete',
    params
  })
}
