<template>
  <div class="login-container">
    <el-form ref="selected" :model="selected" :rules="rules" class="login-form" auto-complete="on" label-position="left" @submit.prevent="handleSave('selected')">
      <img src="@/assets/vsu_logo_official.png" class="user-avatar center-logo">
      <h3 class="title">VSU HRIS</h3>
      <p v-if="checking" class="white-text" style="text-align: center">
        <img src="@/assets/loading.gif" class="user-avatar" style="width: 35px;display: inline;margin-bottom: -10px;margin-right: 10px;">
        <span>Verifying. Please wait ...</span>
      </p>
      <div v-else>
        <div v-if="!password_changed">
          <div v-if="verified">
            <h3 class="white-text">Set a new password</h3>
            <el-form-item prop="new_password" >
              <span class="svg-container">
                <svg-icon icon-class="key" />
              </span>
              <el-input v-model="selected.password" name="new_password" type="text" auto-complete="on" placeholder="New password" />
            </el-form-item>
            <el-form-item prop="confirm_password" >
              <span class="svg-container">
                <svg-icon icon-class="key" />
              </span>
              <el-input v-model="selected.password_confirmation" name="password_confirmation" type="text" auto-complete="on" placeholder="Confirm new password" />
            </el-form-item>
            <el-form-item>
              <el-button :loading="loading" type="primary" style="width:100%;" @click.native.prevent="handleSave">
                Save
              </el-button>
            </el-form-item>
          </div>
          <div v-else style="color: #fff" class="center">
            <p>
              <i class="el-icon-warning"/> Invalid Email or token.
              <a class="underline-link">
                <router-link :to="'forgot_password'"> Request a new one ?</router-link>
              </a>
            </p>
          </div>
        </div>

        <div v-if="password_changed">
          <p class="white-text center">Password updated. redirecting to dashboard...</p>
        </div>

        <back-to-login/>
      </div>
    </el-form>

  </div>
</template>

<script>
import { User } from '@/api/user'
import { BackToLogin } from '../layout/components'

export default {
  name: 'VerifyToken',
  components: { BackToLogin },
  data() {
    return {
      password_changed: false,
      verified: false,
      checking: true,
      selected: {
        email: null,
        reset_token: null
      },
      loading: false,
      rules: {
        email: [
          { required: true, type: 'email' }
        ]
      }
    }
  },
  created: function() {
    this.selected = this.$route.query
    this.checkToken()
  },
  methods: {
    handleSave() {
      this.loading = true
      User(this.selected, 'set_new_password').then(res => {
        this.login()
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    login() {
      this.$store.dispatch('Login', this.selected).then(() => {
        this.loading = false
        this.password_changed = true
        this.redirect()
      }).catch(() => {
        this.loading = false
      })
    },
    redirect() {
      setTimeout(() => {
        this.$router.push({ path: '/' })
      }, 3000)
    },
    checkToken() {
      User({ email: this.selected.email, reset_token: this.selected.reset_token }, 'verify_token').then(res => {
        this.checking = false
        this.verified = true
      }).catch(() => {
        this.checking = false
      })
    },
    verify(formName) {
      var self = this
      this.loading = true
      this.validate(formName, function(valid) {
        if (valid) {
          self.checkToken()
        } else {
          self.loading = false
        }
      })
    },
    validate: function(formName, cb) {
      if (this.$refs[formName]) {
        this.$refs[formName].validate((valid) => {
          cb(valid)
        })
      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss">
  $bg:#2d3a4b;
  $light_gray:#eee;

  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;
      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: #fff !important;
        }
      }
    }
    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    }
  }

</style>

<style rel="stylesheet/scss" lang="scss" scoped>
  $bg:#004009;
  $dark_gray:#889aa4;
  $light_gray:#eee;
  .login-container {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: $bg;
    .login-form {
      position: absolute;
      left: 0;
      right: 0;
      width: 520px;
      max-width: 100%;
      padding: 35px 35px 15px 35px;
      margin: 120px auto;
    }
    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;
      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }
    .title {
      font-size: 26px;
      font-weight: 400;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
</style>
